import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  InputRef,
  Result,
  Row,
  Space,
} from 'antd'
import { forwardRef, Ref, useImperativeHandle, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { RefStepInterface, StepProps } from '../../../models/DataModels'
import { requiredRule, stringRule } from '../../../utils/rules'

const Checkout = forwardRef(
  ({ data, updateData, hasError }: StepProps, ref: Ref<RefStepInterface>) => {
    useImperativeHandle(ref, () => ({ handleNext }))

    async function handleNext() {
      return await form
        .validateFields()
        .then((result) => {
          return true
        })
        .catch((err) => {
          return false
        })
    }

    const intl = useIntl()
    const [form] = Form.useForm()

    const general = data.general
    const address = data.general.address
    const contacts = data.contacts.contacts
    const iban = (data.financial.iban ?? '').match(/.{1,4}/g)?.join(' ')
    const bic = data.financial.bic

    return (
      <Space direction="vertical" style={{ width: '100%' }} size={40}>
        <Descriptions
          title={intl.formatMessage({ id: 'STEP.CHECKOUT.TITLES.COMPANY' })}
          bordered
        >
          <Descriptions.Item
            label={intl.formatMessage({ id: 'NAME' })}
            span={3}
          >
            {general.name}
          </Descriptions.Item>
          <Descriptions.Item
            label={intl.formatMessage({ id: 'WEBSITE' })}
            span={3}
          >
            {general.website}
          </Descriptions.Item>
          {data.welcome.location !== 'France' && (
            <Descriptions.Item
              label={intl.formatMessage({ id: 'REGISTRATION_NUMBER' })}
              span={3}
            >
              {general.registrationNumber}
            </Descriptions.Item>
          )}
          {data.welcome.location !== 'Other' && (
            <Descriptions.Item
              label={intl.formatMessage({ id: 'VAT_NUMBER' })}
              span={3}
            >
              {general.vat}
            </Descriptions.Item>
          )}
          {data.welcome.location === 'France' && (
            <>
              <Descriptions.Item label={intl.formatMessage({ id: 'SIRET' })}>
                {general.siret}
              </Descriptions.Item>
              <Descriptions.Item label={intl.formatMessage({ id: 'SIREN' })}>
                {general.siren}
              </Descriptions.Item>
              <Descriptions.Item label={intl.formatMessage({ id: 'NAF' })}>
                {general.naf}
              </Descriptions.Item>
            </>
          )}
          <Descriptions.Item
            label={intl.formatMessage({ id: 'ADDRESS' })}
            span={3}
          >
            {address.streetNumber} {address.street}
            <br />
            {address.zipCode} {address.city}
            <br />
            {address.state} {address.country}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          title={intl.formatMessage({ id: 'STEP.CHECKOUT.TITLES.CONTACTS' })}
          bordered
        >
          {contacts.map((contact, index) => (
            <>
              <Descriptions.Item label={intl.formatMessage({ id: 'NAME' })}>
                {contact.firstName} {contact.lastName}
              </Descriptions.Item>
              <Descriptions.Item label={intl.formatMessage({ id: 'EMAIL' })}>
                {contact.email}
              </Descriptions.Item>
              <Descriptions.Item label={intl.formatMessage({ id: 'PHONE' })}>
                {contact.phone}
              </Descriptions.Item>
            </>
          ))}
        </Descriptions>
        <Descriptions
          title={intl.formatMessage({ id: 'STEP.CHECKOUT.TITLES.FINANCIAL' })}
          bordered
        >
          <Descriptions.Item
            label={intl.formatMessage({ id: 'IBAN' })}
            span={2}
          >
            {iban}
          </Descriptions.Item>
          <Descriptions.Item label={intl.formatMessage({ id: 'BIC' })} span={2}>
            {bic}
          </Descriptions.Item>
          {data.welcome.location !== 'France' && (
            <Descriptions.Item
              label={intl.formatMessage({ id: 'ROUTING_NUMBER' })}
              span={3}
            >
              {general.registrationNumber}
            </Descriptions.Item>
          )}
        </Descriptions>
        {false && (
          <Result
            status="success"
            title="Successfully Purchased Cloud Server ECS!"
            subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
            extra={[
              <Button type="primary" key="console">
                Go Console
              </Button>,
              <Button key="buy">Buy Again</Button>,
            ]}
          />
        )}
      </Space>
    )
  }
)

Checkout.displayName = 'Checkout'

export default Checkout
