import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import {
  Form,
  Input,
  Button,
  Tooltip,
  Space,
  Row,
  Col,
  Radio,
  Select,
} from 'antd'
import {
  RefStepInterface,
  StepProps,
  WelcomeData,
} from '../../../models/DataModels'
import { useIntl } from 'react-intl'
import { requiredRule } from '../../../utils/rules'

const Welcome = forwardRef(
  ({ data, updateData, hasError }: StepProps, ref: Ref<RefStepInterface>) => {
    useImperativeHandle(ref, () => ({ handleNext }))

    const intl = useIntl()
    const [form] = Form.useForm()

    const updateWelcomeData = (partial: Partial<WelcomeData>) => {
      updateData({
        welcome: {
          ...data.welcome,
          ...partial,
        },
      })
    }

    async function handleNext() {
      return await form
        .validateFields()
        .then((result) => {
          return true
        })
        .catch((err) => {
          return false
        })
    }

    return (
      <Form
        form={form}
        layout="vertical"
        onValuesChange={(_, allFields) => {
          updateWelcomeData(allFields)
        }}
        initialValues={data.welcome}
      >
        <Form.Item
          name="location"
          label={intl.formatMessage({ id: 'COMPANY_LOCATION' })}
          rules={[requiredRule(intl)]}
        >
          <Radio.Group>
            <Radio.Button value="France">🇫🇷 France</Radio.Button>
            <Radio.Button value="UE">🇪🇺 European Union</Radio.Button>
            <Radio.Button value="Other">🏳 Other</Radio.Button>
          </Radio.Group>
        </Form.Item>
      </Form>
    )
  }
)

Welcome.displayName = 'Generals'

export default Welcome
