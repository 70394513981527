import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { Form, Input, Button, Tooltip, Space, Row, Col } from 'antd'
import {
  General,
  RefStepInterface,
  StepProps,
} from '../../../models/DataModels'
import { searchCompanyWithSiret } from '../../../services/CompanyService'
import { useIntl } from 'react-intl'
import {
  numberRule,
  requiredRule,
  siretRule,
  stringRule,
  urlRule,
} from '../../../utils/rules'

const Generals = forwardRef(
  ({ data, updateData, hasError }: StepProps, ref: Ref<RefStepInterface>) => {
    useImperativeHandle(ref, () => ({ handleNext }))

    const intl = useIntl()

    const [form] = Form.useForm()
    const [shouldPromptSiret, setShouldPromptSiret] = useState<boolean>(
      data.general.siret === undefined
    )

    const updateGeneralData = (partial: Partial<General>) =>
      updateData({
        general: {
          ...data.general,
          ...partial,
        },
      })

    async function handleNext() {
      return await form
        .validateFields()
        .then(async (result) => {
          /**
           * When the company is french
           * The SIRET number is asked to pre-fill all fields automagically
           */
          if (data.welcome.location === 'France' && shouldPromptSiret) {
            await searchSiret() // Trigger the search and wait for it
            return false // Stay on the step
          }
          return true
        })
        .catch((err) => {
          return false
        })
    }

    const searchSiret = async () => {
      if (data.general.siret === undefined) {
        setShouldPromptSiret(false)
        return
      }
      await searchCompanyWithSiret(Number(data.general.siret!)).then(
        (company) => {
          updateGeneralData({
            siren: company.siren,
            name: company.name,
            naf: company.naf,
            address: {
              streetNumber: company.address.streetNumber,
              street: company.address.street,
              zipCode: company.address.zipCode,
              city: company.address.city,
              state: company.address.state,
              country: company.address.country,
            },
          })
          setShouldPromptSiret(false)
        },
        (rejected) => {
          setShouldPromptSiret(false)
        }
      )
    }

    useEffect(() => {
      if (data.welcome.location !== 'France') {
        setShouldPromptSiret(false)
      }
    }, [data.welcome.location])

    return (
      <>
        {shouldPromptSiret &&
          data.general.name === undefined &&
          data.welcome.location == 'France' && (
            <Form
              form={form}
              layout="vertical"
              onValuesChange={(_, allFields) => {
                updateGeneralData(allFields)
              }}
            >
              <Form.Item
                label={
                  <Space>
                    {intl.formatMessage({
                      id: 'STEP.GENERAL.SIRET_PROMPT',
                    })}
                  </Space>
                }
                name="siret"
                rules={[siretRule(intl)]}
              >
                <Input maxLength={14} />
              </Form.Item>
            </Form>
          )}
        {(!shouldPromptSiret || data.general.name !== undefined) && (
          <Form
            form={form}
            layout="vertical"
            initialValues={data.general}
            onValuesChange={(_, allFields) => {
              var nextWebsite = allFields.website
              if (nextWebsite.startsWith('http://')) {
                nextWebsite = nextWebsite.replace(/http:\/\//, 'https://')
              }
              if (!nextWebsite.startsWith('https://')) {
                nextWebsite = 'https://' + nextWebsite
              }
              updateGeneralData({
                ...allFields,
                website: nextWebsite,
              })
            }}
          >
            <Row>
              <Col md={24} xs={24}>
                <Form.Item
                  name="name"
                  label={intl.formatMessage({ id: 'NAME' })}
                  rules={[stringRule(intl), requiredRule(intl)]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {data.welcome.location !== 'France' && (
              <Row>
                <Col md={24} xs={24}>
                  <Form.Item
                    name="registrationNumber"
                    label={intl.formatMessage({ id: 'REGISTRATION_NUMBER' })}
                    rules={[stringRule(intl), requiredRule(intl)]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {data.welcome.location !== 'Other' && (
              <Row>
                <Col md={24} xs={24}>
                  <Form.Item
                    name="vat"
                    label={intl.formatMessage({ id: 'VAT_NUMBER' })}
                    rules={[stringRule(intl), requiredRule(intl)]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={24} xs={24}>
                <Form.Item
                  name="website"
                  label={intl.formatMessage({ id: 'WEBSITE' })}
                  rules={[urlRule(intl), requiredRule(intl)]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            {data.welcome.location === 'France' && (
              <Row gutter={16}>
                <Col md={8} xs={24}>
                  <Form.Item
                    name="siren"
                    label={intl.formatMessage({ id: 'SIREN' })}
                    rules={[stringRule(intl), requiredRule(intl)]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                  <Form.Item
                    name="siret"
                    label={intl.formatMessage({ id: 'SIRET' })}
                    rules={[siretRule(intl), requiredRule(intl)]}
                  >
                    <Input maxLength={14} />
                  </Form.Item>
                </Col>
                <Col md={8} xs={24}>
                  <Form.Item
                    name="naf"
                    label={intl.formatMessage({ id: 'NAF' })}
                    rules={[stringRule(intl), requiredRule(intl)]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row gutter={16}>
              <Col md={4} xs={24}>
                <Form.Item
                  name={['address', 'streetNumber']}
                  label={intl.formatMessage({ id: 'STREET_NUMBER' })}
                  rules={[stringRule(intl)]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={20} xs={24}>
                <Form.Item
                  name={['address', 'street']}
                  label={intl.formatMessage({ id: 'STREET' })}
                  rules={[stringRule(intl), requiredRule(intl)]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col md={6} xs={24}>
                <Form.Item
                  name={['address', 'zipCode']}
                  label={intl.formatMessage({ id: 'ZIP_CODE' })}
                  rules={[stringRule(intl), requiredRule(intl)]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item
                  name={['address', 'city']}
                  label={intl.formatMessage({ id: 'CITY' })}
                  rules={[stringRule(intl), requiredRule(intl)]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item
                  name={['address', 'state']}
                  label={intl.formatMessage({ id: 'STATE' })}
                  rules={[stringRule(intl)]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={6} xs={24}>
                <Form.Item
                  name={['address', 'country']}
                  label={intl.formatMessage({ id: 'COUNTRY' })}
                  rules={[stringRule(intl), requiredRule(intl)]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}
      </>
    )
  }
)

Generals.displayName = 'Generals'

export default Generals
