import { Button, Card, message, Spin, Steps, theme } from 'antd'
import React, { useRef, useState } from 'react'
import Contacts from './steps/Contacts'
import {
  CreateVendorData,
  defaultCreateVendorData,
  RefStepInterface,
  VendorCreationResult,
} from '../../models/DataModels'
import Generals from './steps/Generals'
import RIB from './steps/RIB'
import StepperNavigation from '../stepper-navigation/StepperNavigation'
import Checkout from './steps/Checkout'
import Welcome from './steps/Welcome'
import { createVendor } from '../../services/VendorService'
import { useIntl } from 'react-intl'
import CertificatesManager from '../certificates-manager/CertificatesManager'
import { useNavigate } from 'react-router-dom'

const CompanyFormWrapper = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const [action, setAction] = useState<'create' | 'addCertificates'>('create')

  const [current, setCurrent] = useState<number>(0)
  const [nextIsLoading, setNextIsLoading] = useState<boolean>(false)
  const [data, setData] = useState<CreateVendorData>(defaultCreateVendorData)
  const [creationResult, setCreationResult] = useState<
    VendorCreationResult | undefined
  >(undefined)

  const updateData = (fieldsToUpdate: Partial<CreateVendorData>) => {
    setData({ ...data, ...fieldsToUpdate })
  }
  const WelcomeRef = useRef<RefStepInterface>(null)
  const GeneralRef = useRef<RefStepInterface>(null)
  const ContactRef = useRef<RefStepInterface>(null)
  const RIBRef = useRef<RefStepInterface>(null)
  const CheckoutRef = useRef<RefStepInterface>(null)

  const steps = [
    {
      title: intl.formatMessage({ id: 'STEP.WELCOME.TITLE' }),
      content: (
        <Welcome
          data={data}
          updateData={updateData}
          hasError={false}
          ref={WelcomeRef}
        />
      ),
      ref: WelcomeRef,
    },
    {
      title: intl.formatMessage({ id: 'STEP.GENERAL.TITLE' }),
      content: (
        <Generals
          data={data}
          updateData={updateData}
          hasError={false}
          ref={GeneralRef}
        />
      ),
      ref: GeneralRef,
    },
    {
      title: intl.formatMessage({ id: 'STEP.CONTACTS.TITLE' }),
      content: (
        <Contacts
          data={data}
          updateData={updateData}
          hasError={false}
          ref={ContactRef}
        />
      ),
      ref: ContactRef,
    },
    {
      title: intl.formatMessage({ id: 'STEP.FINANCIAL.TITLE' }),
      content: (
        <RIB
          data={data}
          updateData={updateData}
          hasError={false}
          ref={RIBRef}
        />
      ),
      ref: RIBRef,
    },
    {
      title: intl.formatMessage({ id: 'STEP.CHECKOUT.TITLE' }),
      content: (
        <Checkout
          data={data}
          updateData={updateData}
          hasError={false}
          ref={CheckoutRef}
        />
      ),
    },
  ]

  const next = () => setCurrent(current + 1)
  const prev = () => setCurrent(current - 1)

  /**
   * Previous button tapped callback
   * - Always move to previous step
   */
  const prevTapped = () => prev()

  /**
   * Next button tapped callback
   * 1. Retieve the ref to current step
   * 2. Invoke the steps `handleNext()`
   * 3. Then only if `canGoNext` is true pass to the next step
   */
  const nextTapped = () => {
    setNextIsLoading(true)
    steps[current].ref?.current?.handleNext().then((canGoNext) => {
      if (canGoNext) {
        next()
      }
      setNextIsLoading(false)
    })
  }

  const doneTapped = () => {
    setNextIsLoading(true)
    createVendor(data).then(
      (result) => {
        setCreationResult({
          vendorId: result.vendorId,
          folderId: result.folderId,
        })
        setNextIsLoading(false)
        setAction('addCertificates')
      },
      (_reason) => {
        setNextIsLoading(false)
        // TODO: Handle failure
      }
    )
  }

  const stepHeader = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }))

  const { token } = theme.useToken()

  const contentStyle: React.CSSProperties = {
    color: token.colorTextTertiary,
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 20,
  }

  return (
    <>
      {action == 'create' && (
        <>
          <Spin
            spinning={nextIsLoading}
            tip={intl.formatMessage({ id: 'LOADING_DONT_CLOSE_WINDOW' })}
          >
            <Steps type="navigation" current={current} items={stepHeader} />
            <Card
              style={contentStyle}
              actions={[
                <StepperNavigation
                  key={-1}
                  current={current}
                  length={steps.length}
                  nextIsLoading={nextIsLoading}
                  prevTapped={prevTapped}
                  nextTapped={nextTapped}
                  doneTapped={doneTapped}
                />,
              ]}
            >
              {steps[current].content}
            </Card>
          </Spin>
        </>
      )}
      {action == 'addCertificates' && (
        <Card style={contentStyle}>
          <CertificatesManager vendorDetails={creationResult} />
        </Card>
      )}
    </>
  )
}

export default CompanyFormWrapper
