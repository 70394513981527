import React, { forwardRef, Ref, useImperativeHandle, useState } from 'react'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Col, Form, FormListFieldData, Input, Row, Space } from 'antd'
import { RefStepInterface, StepProps } from '../../../models/DataModels'
import {
  emailRule,
  requiredRule,
  stringRule,
  validPhoneNumberRule,
} from '../../../utils/rules'
import { useIntl } from 'react-intl'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const Contacts = forwardRef(
  ({ data, updateData, hasError }: StepProps, ref: Ref<RefStepInterface>) => {
    useImperativeHandle(ref, () => ({ handleNext }))

    async function handleNext() {
      return await form
        .validateFields()
        .then((result) => {
          return true
        })
        .catch((err) => {
          return false
        })
    }
    const [form] = Form.useForm()

    const onFinish = (values: any) => {}

    const intl = useIntl()
    return (
      <Form
        name="dynamic_form_nest_item"
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        onValuesChange={(data, allFields) =>
          updateData({
            contacts: allFields,
          })
        }
      >
        <Form.List name="contacts" initialValue={data.contacts.contacts}>
          {(fieldsData, { add, remove }) => (
            <>
              {fieldsData.map(({ key, name, ...restField }) => (
                <Row gutter={16} key={key}>
                  <Col md={fieldsData.length > 1 ? 5 : 6} xs={24}>
                    <Form.Item
                      {...restField}
                      name={[name, 'lastName']}
                      rules={[stringRule(intl), requiredRule(intl)]}
                    >
                      <Input
                        placeholder={intl.formatMessage({ id: 'LAST_NAME' })}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={fieldsData.length > 1 ? 5 : 6} xs={24}>
                    <Form.Item
                      {...restField}
                      name={[name, 'firstName']}
                      rules={[stringRule(intl), requiredRule(intl)]}
                    >
                      <Input
                        placeholder={intl.formatMessage({ id: 'FIRST_NAME' })}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={fieldsData.length > 1 ? 5 : 6} xs={24}>
                    <Form.Item
                      {...restField}
                      name={[name, 'email']}
                      rules={[emailRule(intl), requiredRule(intl)]}
                    >
                      <Input
                        placeholder={intl.formatMessage({ id: 'EMAIL' })}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={fieldsData.length > 1 ? 5 : 6} xs={24}>
                    <Form.Item
                      {...restField}
                      name={[name, 'phone']}
                      rules={[validPhoneNumberRule(intl), requiredRule(intl)]}
                    >
                      <PhoneInput
                        onChange={() => {}}
                        placeholder={intl.formatMessage({ id: 'PHONE' })}
                      />
                    </Form.Item>
                  </Col>
                  {fieldsData.length > 1 && (
                    <Col md={4} xs={24}>
                      <Form.Item>
                        <Button
                          type="dashed"
                          danger
                          block
                          onClick={() => remove(name)}
                        >
                          {intl.formatMessage({ id: 'DELETE' })}
                        </Button>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  {intl.formatMessage({ id: 'ADD_CONTACT' })}
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    )
  }
)

Contacts.displayName = 'Contacts'

export default Contacts
