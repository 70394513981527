import { Button, Segmented, Space } from 'antd'
import { useIntl } from 'react-intl'
import { getConfig, setLanguage } from '../../i18n/Metronici18n'

interface Props {
  current: number
  length: number
  nextIsLoading: boolean
  prevTapped: () => void
  nextTapped: () => void
  doneTapped: () => void
}
const StepperNavigation = ({
  current,
  length,
  nextIsLoading,
  prevTapped,
  nextTapped,
  doneTapped,
}: Props) => {
  const intl = useIntl()

  return (
    <Space
      style={{
        justifyContent: current == 0 ? 'right' : 'space-between',
        width: '100%',
        padding: '10px 20px',
      }}
    >
      {current > 0 && (
        <Button onClick={prevTapped}>
          {intl.formatMessage({ id: 'PREVIOUS' })}
        </Button>
      )}
      {current < length - 1 && (
        <Button type="primary" onClick={nextTapped} loading={nextIsLoading}>
          {intl.formatMessage({ id: current == 0 ? 'START' : 'NEXT' })}
        </Button>
      )}
      {current === length - 1 && (
        <Button type="primary" onClick={doneTapped} loading={nextIsLoading}>
          {intl.formatMessage({ id: 'SUBMIT' })}
        </Button>
      )}
    </Space>
  )
}

export default StepperNavigation
