export const API_URL = process.env.REACT_APP_API_URL

export const COMPANY_URL = API_URL + '/companies'
export const COMPANY_SEARCH_URL = COMPANY_URL + '/search'

export const VENDORS_URL = API_URL + '/vendors'

export const certificateUrl = (
  vendorId: string,
  certificate: string,
  folderId: string
) =>
  `${API_URL}/vendors/${vendorId}/certificates/${certificate}?folderId=${folderId}`
