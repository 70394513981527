import { Button, Form, Result, Space, Tooltip, Upload } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { InfoCircleOutlined } from '@ant-design/icons'
import { VendorCreationResult } from '../../models/DataModels'
import { certificateUrl } from '../../utils/urls'
import { useIntl } from 'react-intl'
import { useState } from 'react'
import { requiredRule } from '../../utils/rules'

interface Props {
  vendorDetails: VendorCreationResult | undefined
}

const CertificatesManager = ({ vendorDetails }: Props) => {
  const intl = useIntl()

  const [formIsCompleted, setFormIsCompleted] = useState<boolean>(false)
  const certificates = [
    {
      id: 1,
      name: 'kbis',
      url: certificateUrl(
        vendorDetails!.vendorId,
        'kbis',
        vendorDetails!.folderId
      ),
      label: 'KBIS',
      tooltip: 'KBIS',
      max: 1,
      required: true,
    },
    {
      id: 2,
      name: 'attestation-assurance',
      url: certificateUrl(
        vendorDetails!.vendorId,
        'attestation-assurance',
        vendorDetails!.folderId
      ),
      label: "Attestation d'assurance",
      tooltip: "Attestation d'assurance",
      max: 1,
      required: true,
    },
    {
      id: 3,
      name: 'attestation-vigilance-urssaf',
      url: certificateUrl(
        vendorDetails!.vendorId,
        'attestation-vigilance-urssaf',
        vendorDetails!.folderId
      ),
      label: 'Attestation de vigilance URSAFF',
      tooltip: 'Attestation de vigilance URSAFF',
      max: 1,
      required: true,
    },
    {
      id: 4,
      name: 'attestation-regularite-fiscale',
      url: certificateUrl(
        vendorDetails!.vendorId,
        'attestation-regularite-fiscale',
        vendorDetails!.folderId
      ),
      label: 'Attestation régularité fiscale',
      tooltip: 'Attestation régularité fiscale',
      max: 1,
      required: true,
    },
  ]

  return vendorDetails !== undefined ? (
    <>
      {!formIsCompleted && (
        <Form layout="vertical" onFinish={() => setFormIsCompleted(true)}>
          {certificates.map((certificate) => (
            <Form.Item
              key={certificate.id}
              name={certificate.name}
              rules={certificate.required ? [requiredRule(intl)] : []}
              label={
                <Tooltip title={certificate.tooltip} placement="right">
                  <Space>
                    {certificate.label}
                    <InfoCircleOutlined />
                  </Space>
                </Tooltip>
              }
            >
              <Upload
                action={certificate.url}
                listType="picture-card"
                maxCount={certificate.max}
                accept={'application/pdf'}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>
                    {intl.formatMessage({ id: 'UPLOAD' })}
                  </div>
                </div>
              </Upload>
            </Form.Item>
          ))}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {intl.formatMessage({ id: 'SUBMIT' })}
            </Button>
          </Form.Item>
        </Form>
      )}
      {formIsCompleted && (
        <Result
          status="success"
          title={intl.formatMessage({
            id: 'CERTIFICATE_MANAGER.SUCCESS_TITLE',
          })}
          subTitle={
            intl.formatMessage({
              id: 'CERTIFICATE_MANAGER.SUCCESS_SUB_TITLE',
            }) + vendorDetails.vendorId
          }
          extra={[
            <Button
              type="primary"
              key="console"
              onClick={() => window.location.reload()}
            >
              {intl.formatMessage({ id: 'CERTIFICATE_MANAGER.RELOAD_BUTTON' })}
            </Button>,
          ]}
        />
      )}
    </>
  ) : (
    <></>
  )
}

export default CertificatesManager
