import { Rule } from 'antd/es/form'
import { IntlShape } from 'react-intl'
import { isValidPhoneNumber } from 'react-phone-number-input'

export const integerRule: Rule = {
  type: 'integer',
  message: 'The input is not valid number',
}

export const numberRule = function (intl: IntlShape): Rule {
  return {
    type: 'number',
    message: intl.formatMessage({ id: 'FIELD.ERROR.TYPE_NUMBER' }),
  }
}

export const stringRule = function (intl: IntlShape): Rule {
  return {
    type: 'string',
    message: intl.formatMessage({ id: 'FIELD.ERROR.TYPE_TEXT' }),
  }
}

export const urlRule = function (intl: IntlShape): Rule {
  return {
    type: 'url',
    message: intl.formatMessage({ id: 'FIELD.ERROR.TYPE_URL' }),
  }
}

export const siretRule = function (intl: IntlShape): Rule {
  return {
    pattern: new RegExp(/\d{14}/),
    message: intl.formatMessage({ id: 'FIELD.ERROR.TYPE_SIRET' }),
  }
}

export const emailRule = function (intl: IntlShape): Rule {
  return {
    type: 'email',
    message: intl.formatMessage({ id: 'FIELD.ERROR.TYPE_EMAIL' }),
  }
}

export const requiredRule = function (intl: IntlShape): Rule {
  return {
    required: true,
    message: intl.formatMessage({ id: 'FIELD.ERROR.REQUIRE' }),
  }
}

export const validPhoneNumberRule = function (intl: IntlShape): Rule {
  return ({ getFieldValue }) => ({
    validator(_, value: any) {
      return value && isValidPhoneNumber(value)
        ? Promise.resolve()
        : Promise.reject(
            new Error(intl.formatMessage({ id: 'FIELD.ERROR.NOT_VALID_PHONE' }))
          )
    },
  })
}
