import React from 'react'
import { Layout, Space, theme } from 'antd'
import RightHeader from './RightHeader'
import { LayoutFooter } from './LayoutFooter'
import { Outlet, useNavigate } from 'react-router-dom'

const { Header, Content, Footer } = Layout

export const PageLayout: React.FC = () => {
  return (
    <Layout hasSider style={{ minHeight: '100vh' }}>
      <Layout style={{ background: '#f8f8f2' }}>
        <Header
          style={{
            background: '#ffffff',
            position: 'fixed',
            width: '100vw',
            zIndex: 500,
            padding: '0 20px',
          }}
        >
          <Space
            direction="horizontal"
            style={{ justifyContent: 'space-between', width: '100%' }}
          >
            <img
              alt="Logo"
              src="assets/logo_ps.svg"
              style={{
                float: 'left',
                height: 46,
              }}
            />
            <RightHeader />
          </Space>
        </Header>

        <Content
          style={{
            margin: '0',
            paddingTop: 80,
            background: '#f8f8f2',
            maxWidth: '1000px',
            width: '100%',
            paddingRight: 10,
            paddingLeft: 10,
            marginRight: 'auto',
            marginLeft: 'auto',
          }}
        >
          <Outlet />
        </Content>
        <LayoutFooter />
      </Layout>
    </Layout>
  )
}
