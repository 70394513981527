import { FC } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import App from '../App'
import { PageLayout } from '../layout/PageLayout'
import Home from '../pages/Home'

const AppRoutes: FC = () => {
  return (
    <Routes>
      <Route element={<App />}>
        <Route element={<PageLayout />}>
          <Route path="/" element={<Home />} />
          {/* <Route path="/profile" element={<Profile />} /> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Route>
    </Routes>
  )
}

export { AppRoutes }
