import { Avatar, Button, Dropdown, MenuProps, Space, Typography } from 'antd'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import LangSelector from '../components/lang-selector/LangSelector'
const Text = Typography

const RightHeader = () => {
  //   const navigate = useNavigate()
  const intl = useIntl()

  return (
    <Space>
      <LangSelector></LangSelector>
    </Space>
  )
}

export default RightHeader
