import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { I18nProvider } from './i18n/i18nProvider'
import { AppRoutes } from './routes/AppRoutes'
import { BrowserRouter as Router } from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Router basename="/">
    <React.StrictMode>
      <I18nProvider>
        <AppRoutes />
      </I18nProvider>
    </React.StrictMode>
  </Router>
)
reportWebVitals()
