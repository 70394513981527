import axios from 'axios'
import { Outlet } from 'react-router-dom'
import { setupAxios } from './utils/setupAxios'

function App() {
  /**
   * Inject interceptors for axios.
   *
   * @see https://github.com/axios/axios#interceptors
   */
  setupAxios(axios)

  return <Outlet />
}

export default App
