import axios from 'axios'
import { VENDORS_URL } from '../utils/urls'
import { CreateVendorData } from '../models/DataModels'

export async function createVendor(vendor: CreateVendorData) {
  /**
   * Map data before sending it
   */
  const vendorToUpload = {
    name: vendor.general.name,
    siret: vendor.general.siret,
    registrationNumber: vendor.general.registrationNumber,
    vat: vendor.general.vat,
    website: vendor.general.website,

    address: {
      streetNumber: vendor.general.address.streetNumber,
      street: vendor.general.address.street,
      city: vendor.general.address.city,
      zipCode: vendor.general.address.zipCode,
      state: vendor.general.address.state,
      country: vendor.general.address.country,
    },

    contacts: vendor.contacts.contacts,

    iban: vendor.financial.iban,
    bic: vendor.financial.bic,
    routingNumber: vendor.financial.routingNumber ?? '',
  }

  return await axios
    .post(VENDORS_URL, vendorToUpload)
    .then((response) => response.data.data)
}
