///Step 1 -> Siret + Adress
///Step 2 array de contact
///Step 3 Iban

import { Ref } from 'react'

export interface Address {
  streetNumber: string | undefined
  street: string | undefined
  zipCode: string | undefined
  city: string | undefined
  state: string | undefined
  country: string | undefined
}

export interface WelcomeData {
  location: 'France' | 'UE' | 'Other' | undefined
}

export interface General {
  name: string | undefined
  siret?: string | undefined
  siren?: string | undefined
  vat?: string | undefined
  naf?: string | undefined
  registrationNumber?: string | undefined
  website: string | undefined
  address: Address
}

export interface Contacts {
  contacts: Contact[]
}

export interface Financial {
  iban: string | undefined
  bic: string | undefined
  routingNumber: string | undefined
}

export interface CreateVendorData {
  welcome: WelcomeData
  general: General
  contacts: Contacts
  financial: Financial
}

export interface Contact {
  firstName: String
  lastName: String
  email: String
  phone: String
}

export const defaultCreateVendorData: CreateVendorData = {
  welcome: {
    location: undefined,
  },
  general: {
    siret: undefined,
    siren: undefined,
    name: undefined,
    vat: undefined,
    naf: undefined,
    website: undefined,
    address: {
      streetNumber: undefined,
      street: undefined,
      zipCode: undefined,
      city: undefined,
      state: undefined,
      country: undefined,
    },
  },
  contacts: {
    contacts: [
      {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
      },
    ],
  },
  financial: {
    iban: undefined,
    bic: undefined,
    routingNumber: undefined,
  },
}

export type StepProps = {
  data: CreateVendorData
  updateData: (fieldsToUpdate: Partial<CreateVendorData>) => void
  hasError: boolean
}

export interface RefStepInterface {
  handleNext: () => Promise<boolean>
}

export interface VendorCreationResult {
  vendorId: string
  folderId: string
}
