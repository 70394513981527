import { Col, Form, Input, InputRef, Row } from 'antd'
import { isValidIBAN } from 'ibantools'
import { forwardRef, Ref, useImperativeHandle, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  Financial,
  RefStepInterface,
  StepProps,
} from '../../../models/DataModels'
import { requiredRule, stringRule } from '../../../utils/rules'

const RIB = forwardRef(
  ({ data, updateData, hasError }: StepProps, ref: Ref<RefStepInterface>) => {
    useImperativeHandle(ref, () => ({ handleNext }))

    const intl = useIntl()
    const [form] = Form.useForm()

    const [fieldStatus, setFieldStatus] = useState<
      '' | 'error' | 'warning' | undefined
    >(undefined)

    const input0 = useRef<InputRef>(null)
    const input1 = useRef<InputRef>(null)
    const input2 = useRef<InputRef>(null)
    const input3 = useRef<InputRef>(null)
    const input4 = useRef<InputRef>(null)
    const input5 = useRef<InputRef>(null)
    const input6 = useRef<InputRef>(null)

    const updateFinancialData = (partial: Partial<Financial>) =>
      updateData({
        financial: {
          ...data.financial,
          ...partial,
        },
      })

    async function handleNext() {
      return await form
        .validateFields()
        .then(async (result) => {
          if (!isValidIBAN(data.financial.iban ?? '')) {
            setFieldStatus('error')
            return false
          }
          return true
        })
        .catch((err) => {
          if (!isValidIBAN(data.financial.iban ?? '')) {
            setFieldStatus('error')
          }
          return false
        })
    }

    const spreadPastedOnInputs = (iban: string) => {
      if (iban.length === 0 || iban === '') {
        return
      }
      const ibanChunked = iban.replace(/ /g, '').match(/.{1,4}/g)
      form.setFieldsValue({ ...form.getFieldsValue(true), iban: ibanChunked })
      updateFinancialData({
        iban: ibanChunked!.join(''),
      })
    }

    const moveFocusTo = (inputId: number) => {
      switch (inputId) {
        case 0:
          input0.current && input0.current.focus()
          break
        case 1:
          input1.current && input1.current.focus()
          break
        case 2:
          input2.current && input2.current.focus()
          break
        case 3:
          input3.current && input3.current.focus()
          break
        case 4:
          input4.current && input4.current.focus()
          break
        case 5:
          input5.current && input5.current.focus()
          break
        case 6:
          input6.current && input6.current.focus()
          break
        default:
          break
      }
    }

    return (
      <>
        <Form
          labelCol={{ span: 4 }}
          layout="vertical"
          form={form}
          onValuesChange={(_, allFields) => {
            updateFinancialData({
              iban: allFields.iban.join(''),
              bic: form.getFieldValue('bic'),
              routingNumber: form.getFieldValue('routingNumber'),
            })
          }}
          onFieldsChange={(changedFields, allFields) => {
            // Move focus to next field when the latest updated field has a length of 4
            if (
              changedFields[0] &&
              changedFields[0].value.length === 4 &&
              Array.isArray(changedFields[0].name) &&
              changedFields[0].name.includes('iban')
            ) {
              moveFocusTo(Number(changedFields[0].name[1]) + 1)
            }
          }}
          initialValues={{
            iban: (data.financial.iban ?? '')
              .match(/.{1,4}/g)
              ?.map((item) => item),
          }}
        >
          <Form.Item
            label={intl.formatMessage({ id: 'IBAN' })}
            name="_ibanWrapper"
            validateStatus={fieldStatus}
            help={
              fieldStatus == 'error'
                ? intl.formatMessage({ id: 'FIELD.ERROR.TYPE_IBAN' })
                : ''
            }
            required
          >
            <Row gutter={0} justify="space-between">
              <Col md={3} xs={24}>
                <Form.Item name={['iban', 0]} style={{ margin: 0 }}>
                  <Input
                    maxLength={4}
                    ref={input0}
                    onPaste={(e) =>
                      spreadPastedOnInputs(e.clipboardData.getData('Text'))
                    }
                    status={fieldStatus}
                  />
                </Form.Item>
              </Col>
              <Col md={3} xs={24}>
                <Form.Item name={['iban', 1]} style={{ margin: 0 }}>
                  <Input maxLength={4} ref={input1} status={fieldStatus} />
                </Form.Item>
              </Col>
              <Col md={3} xs={24}>
                <Form.Item name={['iban', 2]} style={{ margin: 0 }}>
                  <Input maxLength={4} ref={input2} status={fieldStatus} />
                </Form.Item>
              </Col>
              <Col md={3} xs={24}>
                <Form.Item name={['iban', 3]} style={{ margin: 0 }}>
                  <Input maxLength={4} ref={input3} status={fieldStatus} />
                </Form.Item>
              </Col>
              <Col md={3} xs={24}>
                <Form.Item name={['iban', 4]} style={{ margin: 0 }}>
                  <Input maxLength={4} ref={input4} status={fieldStatus} />
                </Form.Item>
              </Col>
              <Col md={3} xs={24}>
                <Form.Item name={['iban', 5]} style={{ margin: 0 }}>
                  <Input maxLength={4} ref={input5} status={fieldStatus} />
                </Form.Item>
              </Col>
              <Col md={3} xs={24}>
                <Form.Item name={['iban', 6]} style={{ margin: 0 }}>
                  <Input maxLength={3} ref={input6} status={fieldStatus} />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            rules={[stringRule(intl), requiredRule(intl)]}
            label={intl.formatMessage({ id: 'BIC' })}
            name="bic"
            initialValue={data.financial.bic}
          >
            <Input />
          </Form.Item>
          {data.welcome.location !== 'France' && (
            <Form.Item
              rules={[stringRule(intl), requiredRule(intl)]}
              label={intl.formatMessage({ id: 'ROUTING_NUMBER' })}
              name="routingNumber"
              initialValue={data.financial.routingNumber}
            >
              <Input />
            </Form.Item>
          )}
        </Form>
      </>
    )
  }
)

RIB.displayName = 'RIB'

export default RIB
